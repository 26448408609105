import cx from 'classnames'

import MenuItem, { type ItemType, type MenuType } from './menu-item'

interface MenuProps {
  id?: string
  menuType?: MenuType
  items: ItemType[]
  hasFocus?: boolean
  useMegaNav?: boolean
  onClick?: () => void
  className?: string
}

const Menu = ({
  id,
  menuType,
  items,
  hasFocus = true,
  useMegaNav = false,
  onClick,
  className,
}: MenuProps) => {
  return (
    <ul
      className={cx(
        {
          'flex flex-col gap-y-2': menuType === 'footer',
          'flex flex-col xs:flex-row gap-5': menuType === 'footer-legal',
          'flex flex-wrap gap-x-5 gap-y-2': menuType === 'header-desktop',
          'flex flex-col gap-y-3.5': menuType === 'mega-navigation',
        },
        className
      )}
    >
      {items.map((item) => (
        <li key={item._key}>
          <MenuItem
            menuId={id}
            menuType={menuType}
            item={item}
            hasFocus={hasFocus}
            onClick={onClick}
            useMegaNav={useMegaNav}
          />
        </li>
      ))}
    </ul>
  )
}

export default Menu
