import cx from 'classnames'

interface HamburgerProps {
  isOpened?: boolean
  className?: string
}

const Hamburger = ({ isOpened, className }: HamburgerProps) => (
  <div
    className={cx(
      'relative flex flex-col items-center justify-center mx-auto w-6',
      {
        'mt-[-3px]': isOpened,
      },
      className
    )}
  >
    <div
      className={cx(
        'relative w-full h-0 border-t-[1.5px] border-current my-[6px] transition-transform duration-[.2s]',
        {
          'transform translate-y-[5px] opacity-0': isOpened,
        }
      )}
    />

    {/* Icon */}
    <div
      className={cx('block relative w-full h-0 border-t-[1.5px]', {
        'border-transparent': isOpened,
        'border-current': !isOpened,
      })}
    >
      <div
        className={cx(
          'absolute w-full h-0 border-t-[1.5px] border-current top-[calc(50%-.5px)] transition-transform duration-[.2s]',
          {
            'opacity-0': !isOpened,
            'opacity-100 transform rotate-45': isOpened,
          }
        )}
      />
      <div
        className={cx(
          'absolute w-full h-0 border-t-[1.5px] border-current top-[calc(50%-.5px)] transition-transform duration-[.2s]',
          {
            'opacity-0': !isOpened,
            'opacity-100 transform -rotate-45': isOpened,
          }
        )}
      />
    </div>

    <div
      className={cx(
        'relative w-full h-0 border-t-[1.5px] border-current my-[6px] transition-transform duration-[.2s]',
        {
          'transform translate-y-[-5px] opacity-0': isOpened,
        }
      )}
    />
  </div>
)

export default Hamburger
