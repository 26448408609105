import cx from 'classnames'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'

import LogoLink from './logo-link'

interface LogoProps {
  logo?: SanityImageFragment
  className?: string
}

const Logo = ({ logo, className }: LogoProps) => {
  return (
    <div className={cx('relative', className)}>
      {logo?.asset && (
        <LogoLink image={logo} className={cx('transition-opacity')} />
      )}
    </div>
  )
}

export default Logo
