import { type ReactNode } from 'react'
import { type Config, usePopperTooltip } from 'react-popper-tooltip'

type TooltipProps = Pick<Config, 'placement' | 'trigger'> & {
  content: ReactNode
  children: ReactNode
  className?: string
}

export default function Tooltip({
  content,
  children,
  placement,
  trigger,
  className,
}: TooltipProps) {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement,
      trigger,
      interactive: true,
    })
  const { style, ...tooltipProps } = getTooltipProps()

  return (
    <>
      <div ref={setTriggerRef} className={className}>
        {children}
      </div>

      {visible && (
        <div
          ref={setTooltipRef}
          className="py-4 px-6 bg-gray-100 opacity-95 z-20"
          style={{
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.18)',
            ...style,
          }}
          {...tooltipProps}
        >
          {content}
        </div>
      )}
    </>
  )
}
