import { useContext } from 'react'
import { motion } from 'framer-motion'
import cx from 'classnames'

import { SanityHeaderSettings } from '@data/sanity/queries/types/site'
import { SiteContext } from '@lib/site-context'

import Menu from '@blocks/navigation/menu'

type MobileMenuProps = Pick<
  SanityHeaderSettings,
  'menuMobilePrimary' | 'menuMobileSecondary'
>

const MobileMenu = ({
  menuMobilePrimary,
  menuMobileSecondary,
}: MobileMenuProps) => {
  const { mobileMenu, toggleMobileMenu } = useContext(SiteContext)

  return (
    <nav id="mobile-nav" className="lg:hidden flex justify-end items-center">
      <div className="flex items-center">
        <motion.div
          initial="hide"
          animate={mobileMenu.isOpen ? 'show' : 'hide'}
          variants={{
            show: {
              x: '0%',
            },
            hide: {
              x: '-100%',
            },
          }}
          transition={{
            duration: 0.8,
            ease: [0.16, 1, 0.3, 1],
          }}
          className={cx(
            'fixed top-0 left-0 w-full h-screen z-50 flex flex-col bg-pageBG text-pageText'
          )}
        >
          <div className="container flex flex-col gap-y-12 pb-10 pt-[var(--headerHeight)] overflow-y-scroll no-scrollbar">
            {!!menuMobilePrimary?.items && (
              <Menu
                id={menuMobilePrimary.slug.current}
                menuType="header-mobile-primary"
                items={menuMobilePrimary.items}
                onClick={() => toggleMobileMenu(false)}
              />
            )}

            {!!menuMobileSecondary?.items && (
              <Menu
                id={menuMobileSecondary.slug.current}
                menuType="header-mobile-secondary"
                items={menuMobileSecondary.items}
                onClick={() => toggleMobileMenu(false)}
              />
            )}
          </div>
        </motion.div>

        <div
          className={cx(
            'fixed inset-0 z-40 bg-backdrop bg-opacity-40 pointer-events-none transition-opacity duration-150 ease-linear',
            {
              'opacity-0': !mobileMenu.isOpen,
              'pointer-events-auto opacity-100': mobileMenu.isOpen,
            }
          )}
          onClick={() => toggleMobileMenu(false)}
          role="presentation"
        />
      </div>
    </nav>
  )
}

export default MobileMenu
