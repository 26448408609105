import { type ChangeEvent, useCallback, useContext, useMemo } from 'react'

import {
  type SanityProductFragment,
  type SanityProductOption,
  type SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { ProductContext } from '@lib/product/context'
import {
  getProductOptionInputs,
  getVariantFromOptions,
} from '@lib/product/variant'
import { StringsContext } from '@lib/strings-context'

import Icon from '@components/icon'
import InputDropdown from '@components/input-dropdown/with-managed-state'
import InputField from '@components/input-field/with-managed-state'
import NewLineToBr from '@components/new-line-to-br'
import Tooltip from '@components/tooltip'

interface ProductOptionsSelectProps {
  product: SanityProductFragment
  activeVariant: SanityProductVariantFragment
  onChange: (variant: SanityProductVariantFragment) => void
  onPreview?: (variant?: SanityProductVariantFragment) => void
}

const ProductOptionsSelect = ({
  product,
  activeVariant,
  onChange,
  onPreview,
}: ProductOptionsSelectProps) => {
  const { passepartoutHoleSize, setPassepartoutHoleSize } =
    useContext(ProductContext)
  const strings = useContext(StringsContext)

  const productOptionInputs = useMemo(() => {
    return getProductOptionInputs(product, activeVariant)
  }, [activeVariant, product])

  const handleChange = useCallback(
    async (
      option: SanityProductOption,
      event: ChangeEvent<HTMLSelectElement>
    ) => {
      const newValue = event.target.value

      if (!product.variants || !activeVariant) {
        return
      }

      let newVariant = getVariantFromOptions(
        product.variants,
        activeVariant.options,
        option.name,
        newValue
      )

      if (!newVariant) {
        // Try getting variant without using options with a higher position than the changed option
        newVariant = getVariantFromOptions(
          product.variants,
          activeVariant.options,
          option.name,
          newValue,
          option.position
        )
      }

      if (!newVariant || newVariant.variantID === activeVariant.variantID) {
        return
      }

      onChange(newVariant)
      onPreview?.(newVariant)
    },
    [activeVariant, onChange, onPreview, product]
  )

  if (product.options.length === 0) {
    return null
  }

  return (
    <div className="max-w-sm w-full space-y-5">
      {productOptionInputs.map((input, index) => (
        <InputDropdown
          key={input.option.name}
          options={input.dropdownOptions}
          label={input.option.name}
          // Set value from active variant options without managing input state separately
          value={activeVariant.options[index]?.value}
          setValue={() => {}}
          // On change update active variant, which in turn will update input value
          onChange={(event) => handleChange(input.option, event)}
        />
      ))}

      {product.combinedListingProductType === 'passepartout' && (
        <InputField
          type="text"
          value={passepartoutHoleSize}
          setValue={setPassepartoutHoleSize}
          label={
            <>
              {strings.productPassepartoutHoleSize}
              {!!strings.productPassepartoutHoleSizeTooltip && (
                <Tooltip
                  content={
                    <p>
                      <NewLineToBr>
                        {strings.productPassepartoutHoleSizeTooltip}
                      </NewLineToBr>
                    </p>
                  }
                  placement="auto"
                  trigger={['click', 'hover']}
                  className="inline-block ml-1"
                >
                  <Icon
                    id="passepartout-cut-out-size-tooltip-icon"
                    name="QuestionMark"
                    title={strings.productPassepartoutHoleSize}
                  />
                </Tooltip>
              )}
            </>
          }
          placeholder={strings.productPassepartoutHoleSizePlaceholder}
        />
      )}
    </div>
  )
}

export default ProductOptionsSelect
