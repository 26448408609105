import cx from 'classnames'
import { type Variants, motion } from 'framer-motion'
import { useState } from 'react'

import { type SanityLinkFragment } from '@data/sanity/queries/types/link'

import DropdownButton from './dropdown-button'
import DropdownItem from './dropdown-item'

const dropdownAnimation: Variants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  closed: {
    opacity: 0,
    height: 0,
  },
}

interface DropdownProps {
  id: string
  title: string
  items: SanityLinkFragment[]
  onClick?: () => void
  buttonClassName?: string
  listClassName?: string
  listItemClassName?: string
  listItemLinkClassName?: string
}

const Dropdown = ({
  id,
  title,
  items,
  onClick,
  buttonClassName,
  listClassName,
  listItemClassName,
  listItemLinkClassName,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="relative z-10">
      <DropdownButton
        id={`dropdown-${id}`}
        isActive={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        className={buttonClassName}
      >
        {title}
      </DropdownButton>

      <motion.div
        id={`dropdown-${id}`}
        className={cx(
          'relative h-0 overflow-y-hidden',
          'lg:absolute lg:top-full lg:whitespace-nowrap lg:overflow-y-visible lg:opacity-0 lg:invisible lg:pointer-events-none lg:transition-all lg:h-auto'
        )}
        initial={isOpen ? 'open' : 'closed'}
        animate={isOpen ? 'open' : 'closed'}
        variants={dropdownAnimation}
        transition={{
          duration: 0.5,
          ease: [0.19, 1.0, 0.22, 1.0],
        }}
      >
        <ul className={cx('block relative', listClassName)}>
          {items.map((item) => (
            <DropdownItem
              key={item._key}
              item={item}
              isOpen={isOpen}
              onClick={onClick}
              className={listItemClassName}
              linkClassName={listItemLinkClassName}
            />
          ))}
        </ul>
      </motion.div>
    </div>
  )
}

export default Dropdown
