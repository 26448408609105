import { type ReactNode, useCallback, useContext, useMemo } from 'react'

import { useIsTouchDevice } from '@lib/helpers'
import { SearchContext } from '@lib/search-context'
import { SiteContext } from '@lib/site-context'

import DropdownButton from './dropdown-button'

interface MegaNavigationButtonProps {
  id: string
  children?: ReactNode
  className?: string
}

const MegaNavigationButton = ({
  id,
  children,
  className,
}: MegaNavigationButtonProps) => {
  const { toggleSearchDrawer } = useContext(SearchContext)
  const {
    megaNavigation,
    megaNavigationTimeout,
    setMegaNavigationTimeout,
    toggleMegaNavigation,
  } = useContext(SiteContext)

  const isTouchDevice = useIsTouchDevice()

  const isActive = useMemo(
    () => megaNavigation?.activeId === id,
    [id, megaNavigation]
  )

  const handleClick = useCallback(() => {
    if (isTouchDevice) {
      toggleSearchDrawer(false)
      toggleMegaNavigation(!isActive ? true : 'toggle', id)
    }
  }, [id, isActive, isTouchDevice, toggleMegaNavigation, toggleSearchDrawer])

  const handleMouseEnter = useCallback(() => {
    if (!isTouchDevice) {
      if (megaNavigationTimeout) {
        window.clearTimeout(megaNavigationTimeout)
      }

      toggleSearchDrawer(false)
      toggleMegaNavigation(true, id)
    }
  }, [
    id,
    isTouchDevice,
    megaNavigationTimeout,
    toggleMegaNavigation,
    toggleSearchDrawer,
  ])

  const handleMouseLeave = useCallback(() => {
    if (!isTouchDevice) {
      setMegaNavigationTimeout(
        window.setTimeout(() => {
          toggleMegaNavigation(false)
        }, 300)
      )
    }
  }, [isTouchDevice, setMegaNavigationTimeout, toggleMegaNavigation])

  return (
    <DropdownButton
      id={`meganav-${id}`}
      isActive={isActive}
      className={className}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </DropdownButton>
  )
}

export default MegaNavigationButton
