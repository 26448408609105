import cx from 'classnames'
import FocusTrap from 'focus-trap-react'
import { KeyboardEvent, MouseEvent, type ReactNode, useEffect } from 'react'

import DrawerMenuBackdrop from '@components/drawer-menu/backdrop'
import DrawerMenuBackground from '@components/drawer-menu/background'

interface DrawerMenuProps {
  headerHeight: number
  activeDropdownHeight?: number
  isOpen: boolean
  hasFocus: boolean
  hasActiveLinks: boolean
  onClose: () => void
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void
  onMouseEnter?: (event: MouseEvent<HTMLDivElement>) => void
  onMouseLeave?: (event: MouseEvent<HTMLDivElement>) => void
  children: ReactNode
  menuClassName?: string
  backgroundClassName?: string
  backdropClassName?: string
}

const DrawerMenu = ({
  headerHeight,
  activeDropdownHeight,
  isOpen,
  onKeyDown,
  onMouseEnter,
  onMouseLeave,
  hasFocus,
  hasActiveLinks,
  onClose,
  children,
  menuClassName,
  backgroundClassName,
  backdropClassName,
}: DrawerMenuProps) => {
  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', isOpen)
  }, [isOpen])

  return (
    <>
      <FocusTrap
        active={isOpen && hasFocus && hasActiveLinks}
        focusTrapOptions={{
          allowOutsideClick: true,
        }}
      >
        <div
          role="menu"
          tabIndex={-1}
          onKeyDown={onKeyDown}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          className={cx(
            'container absolute top-full inset-x-0 z-30 bg-pageBG',
            menuClassName
          )}
        >
          {children}
        </div>
      </FocusTrap>

      <DrawerMenuBackground
        height={isOpen ? headerHeight + (activeDropdownHeight ?? 0) : 0}
        className={backgroundClassName}
      />
      <DrawerMenuBackdrop
        isOpen={isOpen}
        onClose={onClose}
        className={backdropClassName}
      />
    </>
  )
}

export default DrawerMenu
