import { useContext, useMemo } from 'react'

import { type SanityCompany } from '@data/sanity/queries/types/customer'
import {
  type SanityCombinedListingConfiguration,
  type SanityCombinedListingProductFragment,
  type SanityCombinedListingProductVariantFragment,
} from '@data/sanity/queries/types/product'
import {
  getCollectionDiscounts,
  getProductDiscounts,
} from '@lib/discount/product'
import {
  getDocumentPercentDiscount,
  getProductPrices,
} from '@lib/product/price'
import { UserContext } from '@lib/user/context'
import { getIsEmptyFrame } from './helpers'

type PriceAndComparePrice = [number | undefined, number | undefined]

const emptyPriceAndComparePrice: PriceAndComparePrice = [undefined, undefined]

/**
 * Gets the price of a empty frame product.
 */
const getCombinedListingEmptyFramePrice = (
  product?: SanityCombinedListingProductFragment,
  productVariant?: SanityCombinedListingProductVariantFragment,
  company?: SanityCompany
) => {
  // Empty frame company discounts
  const productDiscounts = getProductDiscounts(company?.productDiscounts)
  const collectionDiscounts = getCollectionDiscounts(
    company?.collectionDiscounts
  )
  const productPercentDiscount = getDocumentPercentDiscount(
    productDiscounts ?? [],
    'product',
    product?._id ? [product._id] : []
  )
  const collectionPercentDiscount = getDocumentPercentDiscount(
    collectionDiscounts ?? [],
    'collection',
    product?.collectionIds ?? []
  )

  return getProductPrices({
    productPrice: product?.price,
    productComparePrice: product?.comparePrice,
    variantPrice: productVariant?.price,
    variantComparePrice: productVariant?.comparePrice,
    productPercentDiscount,
    collectionPercentDiscount,
    companyPercentDiscount: company?.percentDiscount,
  })
}

/**
 * Get the full price of a combined listing product.
 */
const getCombinedListingFullPrice = (
  combinedListingProducts: SanityCombinedListingProductFragment[],
  combinedListingProductVariants: SanityCombinedListingProductVariantFragment[]
) => {
  return combinedListingProductVariants.reduce(
    ([previousPrice, previousComparePrice], combinedListingProductVariant) => {
      const combinedListingProduct = combinedListingProducts.find((product) =>
        product.variants?.some(
          (variant) =>
            variant.variantID === combinedListingProductVariant.variantID
        )
      )

      // Price with combined listing discount
      const [price, comparePrice] = getProductPrices({
        variantPrice: combinedListingProductVariant.price,
        variantComparePrice: combinedListingProductVariant.comparePrice,
        combinedListingPercentDiscount:
          combinedListingProduct?.combinedListingPercentDiscount,
      })
      const newPriceAndComparePrice: PriceAndComparePrice = [
        ...emptyPriceAndComparePrice,
      ]

      if (
        typeof previousPrice !== 'undefined' ||
        typeof price !== 'undefined'
      ) {
        newPriceAndComparePrice[0] = (previousPrice ?? 0) + (price ?? 0)
      }

      if (
        typeof previousComparePrice !== 'undefined' ||
        typeof comparePrice !== 'undefined'
      ) {
        newPriceAndComparePrice[1] =
          (previousComparePrice ?? 0) + (comparePrice ?? 0)
      }

      return newPriceAndComparePrice
    },
    emptyPriceAndComparePrice
  )
}

/**
 * Gets the price of a combined listing product.
 */
export const getCombinedListingPrice = (
  combinedListingProducts: SanityCombinedListingProductFragment[],
  combinedListingProductVariants: SanityCombinedListingProductVariantFragment[],
  combinedListingId?: string,
  company?: SanityCompany
) => {
  const isEmptyFrame = getIsEmptyFrame(combinedListingProducts)

  if (isEmptyFrame) {
    return getCombinedListingEmptyFramePrice(
      combinedListingProducts[0],
      combinedListingProductVariants[0],
      company
    )
  }

  const [combineListingPrice, combineListingComparePrice] =
    getCombinedListingFullPrice(
      combinedListingProducts,
      combinedListingProductVariants
    )

  // Combined listing company discounts
  const productDiscounts = getProductDiscounts(company?.productDiscounts)
  const combinedListingPercentDiscount = getDocumentPercentDiscount(
    productDiscounts ?? [],
    'productCombinedListing',
    combinedListingId ? [combinedListingId] : []
  )

  return getProductPrices({
    variantPrice: combineListingPrice,
    variantComparePrice: combineListingComparePrice,
    productPercentDiscount: combinedListingPercentDiscount,
    companyPercentDiscount: company?.percentDiscount,
  })
}

/**
 * Hook that calculates combined listing product price.
 */
export const useCombinedListingProductPrice = (
  combinedListingActiveProducts?: SanityCombinedListingProductFragment[],
  combinedListingActiveVariants?: SanityCombinedListingProductVariantFragment[],
  combinedListingConfiguration?: SanityCombinedListingConfiguration
) => {
  const { user } = useContext(UserContext)

  const [price, comparePrice] = useMemo(
    () =>
      getCombinedListingPrice(
        combinedListingActiveProducts ?? [],
        combinedListingActiveVariants ?? [],
        combinedListingConfiguration?._id,
        user?.company
      ),
    [
      combinedListingActiveProducts,
      combinedListingActiveVariants,
      combinedListingConfiguration,
      user,
    ]
  )

  return [price, comparePrice] as const
}
