import { type SanityCombinedListingSettings } from '@data/sanity/queries/types/product'
import { type SanityCombinedListingConfiguration } from '@data/sanity/queries/types/product'
import { getCombinedListingProductsBySize } from './product'
import {
  type CombinedListingDefaultFrame,
  type CombinedListingDefaultOptions,
} from './types'

/**
 * Gets combined listing product default values for a specific size.
 */
export const getCombinedListingProductDefaultValues = (
  combinedListingSettings: SanityCombinedListingSettings,
  combinedListingConfiguration: SanityCombinedListingConfiguration,
  size: string
) => {
  if (!combinedListingConfiguration || !size) {
    return {}
  }

  // Get available product IDs
  const combinedListing = getCombinedListingProductsBySize(
    combinedListingConfiguration.frame ?? [],
    combinedListingConfiguration.glass ?? [],
    combinedListingConfiguration.backboard ?? [],
    combinedListingConfiguration.passepartout ?? [],
    combinedListingConfiguration.spacer ?? []
  )

  if (!combinedListing[size]) {
    return {}
  }

  const availableGlassProductIds = combinedListing[size].glass.map(
    (product) => product.productID
  )
  const availableBackboardProductIds = combinedListing[size].backboard.map(
    (product) => product.productID
  )
  const availablePassepartoutProductIds = combinedListing[
    size
  ].passepartout.map((product) => product.productID)
  const availableSpacerProductIds = combinedListing[size].spacer.map(
    (product) => product.productID
  )

  // Get default product IDs by type
  const combinedListingDefaultOptions: CombinedListingDefaultOptions = {
    frame: [],
    glass:
      combinedListingConfiguration.defaults?.glassProductIds &&
      combinedListingConfiguration.defaults.glassProductIds.length > 0
        ? combinedListingConfiguration.defaults.glassProductIds
        : combinedListingSettings.defaults?.glassProductIds ?? [],
    backboard:
      combinedListingConfiguration.defaults?.backboardProductIds &&
      combinedListingConfiguration.defaults.backboardProductIds.length > 0
        ? combinedListingConfiguration.defaults.backboardProductIds
        : combinedListingSettings.defaults?.backboardProductIds ?? [],
    passepartout:
      combinedListingConfiguration.defaults?.passepartoutProductIds &&
      combinedListingConfiguration.defaults.passepartoutProductIds.length > 0
        ? combinedListingConfiguration.defaults.passepartoutProductIds
        : combinedListingSettings.defaults?.passepartoutProductIds ?? [],
    spacer:
      combinedListingConfiguration.defaults?.spacerProductIds &&
      combinedListingConfiguration.defaults.spacerProductIds.length > 0
        ? combinedListingConfiguration.defaults.spacerProductIds
        : combinedListingSettings.defaults?.spacerProductIds ?? [],
  }

  // Get default product IDs that are available
  const defaultGlassProductId = combinedListingDefaultOptions.glass.find(
    (productId) => availableGlassProductIds.includes(productId)
  )
  const defaultBackboardProductId =
    combinedListingDefaultOptions.backboard.find((productId) =>
      availableBackboardProductIds.includes(productId)
    )
  const defaultPassepartoutProductId =
    combinedListingDefaultOptions.passepartout.find((productId) =>
      availablePassepartoutProductIds.includes(productId)
    )
  const defaultSpacerProductId = combinedListingDefaultOptions.spacer.find(
    (productId) => availableSpacerProductIds.includes(productId)
  )

  const combinedListingDefaultFrame: CombinedListingDefaultFrame = {
    glassProductId: defaultGlassProductId,
    backboardProductId: defaultBackboardProductId,
    passepartoutProductId: defaultPassepartoutProductId,
    spacerProductId: defaultSpacerProductId,
  }

  return combinedListingDefaultFrame
}
