import cx from 'classnames'
import { type ReactNode } from 'react'

import Icon from '@components/icon'

interface DropdownButtonProps {
  id: string
  isActive: boolean
  children?: ReactNode
  className?: string
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

const DropdownButton = ({
  id,
  isActive,
  children,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: DropdownButtonProps) => {
  return (
    <button
      aria-expanded={isActive}
      aria-controls={`dropdown-button-${id}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cx('group inline-flex items-center gap-1', className)}
    >
      <span
        className={cx({
          'opacity-60': isActive,
        })}
      >
        {children}
      </span>

      <span className="relative inline-block w-[24px] h-[21px] text-[24px]">
        <Icon
          id={`dropdown-button-${id}`}
          name="ChevronDown"
          color="text-current"
          className={cx(
            'absolute left-0 -top-[3px]',
            'transition-transform duration-300 ease-custom-1',
            {
              'transform rotate-180': isActive,
            }
          )}
        />
      </span>
    </button>
  )
}

export default DropdownButton
