import cx from 'classnames'
import { useContext } from 'react'

import {
  type SanityLink,
  type SanityLinkFragment,
} from '@data/sanity/queries/types/link'
import { type SanityMenuFeaturedLinkFragment } from '@data/sanity/queries/types/site'
import { getLinkPageUrl } from '@lib/routes'
import { UserContext } from '@lib/user/context'

import AccountButton from '@components/buttons/account-button'
import LanguageSwitch from '@components/language-switch'
import CustomLink from '@components/link'
import Dropdown from './dropdown'
import MegaNavigationButton from './mega-navigation-button'

export type MenuType =
  | 'header-desktop'
  | 'header-mobile-primary'
  | 'header-mobile-secondary'
  | 'footer'
  | 'footer-legal'
  | 'mega-navigation'

export type ItemType = SanityLinkFragment | SanityMenuFeaturedLinkFragment

interface MenuItemProps {
  menuId?: string
  menuType?: MenuType
  item: ItemType
  hasFocus: boolean
  useMegaNav: boolean
  onClick?: () => void
}

const MenuItem = ({
  menuId,
  menuType,
  item,
  hasFocus,
  useMegaNav,
  onClick,
}: MenuItemProps) => {
  const { user } = useContext(UserContext)

  const title = 'title' in item && item.title ? item.title : ''
  const isButton = 'isButton' in item && item.isButton ? item.isButton : false

  const itemClassNames = cx(
    !isButton
      ? cx(
          'inline-flex hover:opacity-60 transition-opacity duration-200 !no-underline',
          {
            'text-sm': menuType === 'footer',
            'text-xs': menuType === 'footer-legal',
            'font-medium text-sm': menuType === 'header-desktop',
            'w-full font-medium text-base py-5 border-b':
              menuType === 'header-mobile-primary',
            'w-full font-medium text-sm py-3 border-b':
              menuType === 'header-mobile-secondary',
            'font-medium text-base leading-[1.25]':
              menuType === 'mega-navigation',
          }
        )
      : {}
  )

  // Language switch
  if (item._type === 'navLanguageSwitch') {
    return (
      <LanguageSwitch
        id={item._key}
        buttonClassName={itemClassNames}
        hasCurrency={item.displayCurrency}
      />
    )
  }

  // Account button
  if (item._type === 'navAccountButton') {
    const isUserLoggedIn = !!user?.isLoggedIn
    const accountButtonText = isUserLoggedIn
      ? item.accountLabel
      : item.loginLabel
    const accountButtonUrl = getLinkPageUrl({
      pageType: isUserLoggedIn ? 'accountPage' : 'loginPage',
    })

    return (
      <AccountButton
        id={`account-button-${item._key}`}
        href={accountButtonUrl}
        showIcon={item.displayIcon}
        className={cx(itemClassNames, {
          'p-0': !accountButtonText,
        })}
      >
        {accountButtonText}
      </AccountButton>
    )
  }

  // Dropdown list
  if (item._type === 'navDropdown') {
    if (useMegaNav) {
      return (
        <MegaNavigationButton id={item._key} className={itemClassNames}>
          {title}
        </MegaNavigationButton>
      )
    }

    // Dropdown list without mega navigation (e.g. in mobile menu)
    if (!('dropdownItems' in item) || !item.dropdownItems) {
      return null
    }

    return (
      <Dropdown
        id={item._key}
        title={title}
        items={item.dropdownItems}
        onClick={onClick}
        listClassName={cx({
          'flex flex-col gap-5 pl-5 py-6 border-b':
            menuType === 'header-mobile-primary',
        })}
        listItemClassName={cx({
          'mx-2 my-0': menuType === 'header-desktop',
          'ml-3': menuType === 'header-mobile-secondary',
        })}
        buttonClassName={cx(itemClassNames, {
          'justify-between':
            menuType === 'header-mobile-primary' ||
            menuType === 'header-mobile-secondary',
        })}
        listItemLinkClassName={cx(
          'inline-flex hover:opacity-60 transition-opacity duration-200 !no-underline',
          'font-medium text-base leading-[1.25]'
        )}
      />
    )
  }

  // Single link
  const titleSlug =
    'title' in item ? item.title.toLowerCase().replace(/ /g, '-') : ''
  const link: SanityLink = {
    ...item,
    ...(menuId && {
      id: `${menuId}-${titleSlug}`,
    }),
  }

  return (
    <CustomLink
      link={link}
      onClick={onClick}
      tabIndex={!hasFocus ? -1 : 0}
      className={itemClassNames}
    >
      {title}
    </CustomLink>
  )
}

export default MenuItem
