import cx from 'classnames'
import { useContext, useEffect, useRef } from 'react'

import { ProductQuickAddContext } from '@lib/product/quick-add-context'
import { StringsContext } from '@lib/strings-context'

import Icon from '@components/icon'
import QuickAddModalCombinedListingProductControls from './combined-listing-product-controls'
import QuickAddModalProductControls from './product-controls'

const QuickAddModal = () => {
  const { productQuickAdd, closeProductQuickAdd } = useContext(
    ProductQuickAddContext
  )
  const strings = useContext(StringsContext)

  const ref = useRef<HTMLDivElement>(null)

  // Handle outside click, escape key press and body class list when modal is open
  useEffect(() => {
    function handleEscapeKeyPress(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        closeProductQuickAdd()
      }
    }

    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        closeProductQuickAdd()
      }
    }

    if (productQuickAdd.isOpen) {
      document.addEventListener('keydown', handleEscapeKeyPress)
      window.addEventListener('click', handleClickOutside)
    }

    if (!productQuickAdd.isOpen) {
      document.removeEventListener('keydown', handleEscapeKeyPress)
      window.removeEventListener('click', handleClickOutside)
    }

    document.body.classList.toggle('max-h-screen', productQuickAdd.isOpen)
    document.body.classList.toggle('overflow-hidden', productQuickAdd.isOpen)

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress)
      window.removeEventListener('click', handleClickOutside)

      document.body.classList.toggle('max-h-screen', false)
      document.body.classList.toggle('overflow-hidden', false)
    }
  }, [closeProductQuickAdd, productQuickAdd])

  const isCombinedListing =
    productQuickAdd.product?.combinedListingProductType === 'frame'

  return (
    <div
      className={cx(
        'fixed flex items-center justify-center inset-0 z-80 bg-backdrop bg-opacity-25 p-5',
        'transition-opacity duration-150 ease-linear',
        {
          'pointer-events-none opacity-0': !productQuickAdd.isOpen,
          'pointer-events-auto opacity-100': productQuickAdd.isOpen,
        }
      )}
    >
      <div
        ref={ref}
        className="flex flex-col bg-pageBG rounded-lg max-w-[600px] max-h-full w-full"
      >
        <div className="flex items-center justify-between gap-3 px-5 py-3 md:py-5">
          {!!productQuickAdd.product && (
            <h2>{productQuickAdd.product.title}</h2>
          )}
          {!productQuickAdd.product && (
            <span className="text-sm font-medium">
              {strings.productOptionsHeading}
            </span>
          )}

          <button
            onClick={closeProductQuickAdd}
            className="inline-flex justify-center items-center p-2 rounded-full border border-divider text-xs"
          >
            <Icon id="quick-add-modal-close-icon" name="Cross" />
          </button>
        </div>

        {!!productQuickAdd.product && !isCombinedListing && (
          <QuickAddModalProductControls product={productQuickAdd.product} />
        )}
        {!!productQuickAdd.product && isCombinedListing && (
          <QuickAddModalCombinedListingProductControls
            product={productQuickAdd.product}
          />
        )}
      </div>
    </div>
  )
}

export default QuickAddModal
