import { type ReactNode, useContext } from 'react'

import { CartContext } from '@lib/cart/context'
import { StringsContext } from '@lib/strings-context'

import Button, { type ButtonProps } from '@components/buttons/button'

type ProductAddToCartProps = ButtonProps & {
  onClick: () => void
  disabled?: boolean
  className?: string
  children: ReactNode
}

const ProductAddToCart = ({
  onClick,
  variant,
  color,
  size = 'normal',
  icon,
  disabled,
  className,
  children,
}: ProductAddToCartProps) => {
  const strings = useContext(StringsContext)
  const { cart, cartAddingProductIds, isCreatingProduct } =
    useContext(CartContext)

  return (
    <Button
      id="product-add-to-cart-button"
      className={className}
      variant={variant}
      color={color}
      size={size}
      icon={icon}
      disabled={
        !!disabled ||
        !cart ||
        isCreatingProduct ||
        cartAddingProductIds.length > 0
      }
      onClick={onClick}
    >
      {!cart && <>{strings.buttonUnavailable}</>}
      {!!cart && isCreatingProduct && <>{strings.buttonCreatingProduct}</>}
      {!!cart && !isCreatingProduct && cartAddingProductIds.length > 0 && (
        <>{strings.buttonAdding}</>
      )}
      {!!cart && !isCreatingProduct && cartAddingProductIds.length === 0 && (
        <>{children}</>
      )}
    </Button>
  )
}

export default ProductAddToCart
