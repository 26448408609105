import cx from 'classnames'
import { motion } from 'framer-motion'
import { useContext, useEffect, useRef, useMemo } from 'react'
import { useResizeObserver } from 'usehooks-ts'

import { type SanityMenuFeaturedLinkFragment } from '@data/sanity/queries/types/site'
import { swipeAnimation } from '@lib/animate'
import { SiteContext } from '@lib/site-context'
import { StringsContext } from '@lib/strings-context'

import FeaturedProducts from './featured-products'
import Menu from './menu'

interface MegaNavigationDropdownProps {
  dropdown: SanityMenuFeaturedLinkFragment
  hasFocus: boolean
  setHasFocus: (hasFocus: boolean) => void
  setActiveDropdownHeight: (activeDropdownHeight: number) => void
}

const MegaNavigationDropdown = ({
  dropdown,
  hasFocus,
  setHasFocus,
  setActiveDropdownHeight,
}: MegaNavigationDropdownProps) => {
  const { megaNavigation, toggleMegaNavigation } = useContext(SiteContext)
  const strings = useContext(StringsContext)

  const dropdownRef = useRef<HTMLDivElement>(null)
  const dropdownRectangle = useResizeObserver({
    ref: dropdownRef,
  })

  const isDropdownActive = useMemo(
    () => megaNavigation.isOpen && megaNavigation.activeId === dropdown._key,
    [dropdown._key, megaNavigation.activeId, megaNavigation.isOpen]
  )

  useEffect(() => {
    if (isDropdownActive && typeof dropdownRectangle.height !== 'undefined') {
      setActiveDropdownHeight(dropdownRectangle.height)
    }
  }, [dropdownRectangle.height, isDropdownActive, setActiveDropdownHeight])

  return (
    <div
      ref={dropdownRef}
      id={`meganav-${dropdown._key}`}
      className={cx(
        'absolute top-0 inset-x-0 z-10 -mt-px overflow-hidden transition-visibility duration-200',
        {
          'delay-[0s] pointer-events-auto': isDropdownActive,
          'invisible delay-[.8s] pointer-events-none': !isDropdownActive,
        }
      )}
    >
      <div>
        <motion.div
          initial="hide"
          animate={isDropdownActive ? 'show' : 'hide'}
          onAnimationComplete={(definition) =>
            setHasFocus(definition === 'show')
          }
          variants={swipeAnimation}
          className="container grid grid-cols-2 relative py-7 pb-10 will-change-transform"
        >
          <Menu
            menuType="mega-navigation"
            items={dropdown.dropdownItems}
            hasFocus={hasFocus && isDropdownActive}
            onClick={() => toggleMegaNavigation(false)}
          />

          {!!dropdown.featured && dropdown.featured.length > 0 && (
            <div className="grid grid-cols-2 gap-4 max-w-2xl relative w-full pl-8">
              <div className="block absolute top-0 right-full w-2 text-xs uppercase tracking-widest font-semibold">
                <span className="block overflow-hidden absolute top-1/2 left-0 whitespace-nowrap transform rotate-90 origin-top-left">
                  {strings.featuredProducts}
                </span>
              </div>
              <FeaturedProducts
                products={dropdown.featured}
                onClick={() => toggleMegaNavigation(false)}
              />
            </div>
          )}
        </motion.div>
      </div>
    </div>
  )
}

export default MegaNavigationDropdown
