import { type SanityMenuFeaturedLinkFragment } from '@data/sanity/queries/types/site'

/**
 * Counts links in a dropdown.
 */
export const getLinkCount = (dropdown: SanityMenuFeaturedLinkFragment) =>
  dropdown.dropdownItems.length + (dropdown.featured?.length ?? 0)

/**
 * Counts links in all active dropdowns.
 */
export const getActiveLinkCount = (
  dropdowns: SanityMenuFeaturedLinkFragment[],
  isOpen: boolean,
  activeId?: string
) =>
  dropdowns.reduce((total, dropdown) => {
    const linkCount =
      isOpen && activeId === dropdown._key ? getLinkCount(dropdown) : 0
    return total + linkCount
  }, 0)
