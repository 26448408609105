import cx from 'classnames'
import {
  type ChangeEvent,
  type FocusEvent,
  forwardRef,
  useId,
  useMemo,
} from 'react'

import { type DropdownOption } from '@lib/helpers'

import Icon from '../icon'

export interface InputDropdownWithManagedStateProps {
  options: DropdownOption[]
  value?: string
  setValue: (newValue: string) => void
  errorMessage?: string
  name?: string
  label?: string
  isRequired?: boolean
  placeholder?: string
  borderBottom?: boolean
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void
  onBlur?: (event: FocusEvent<HTMLSelectElement>) => void
  className?: string
}

const InputDropdownWithManagedState = forwardRef<
  HTMLSelectElement,
  InputDropdownWithManagedStateProps
>(
  (
    {
      options,
      value,
      setValue,
      errorMessage,
      name,
      label,
      isRequired,
      placeholder,
      borderBottom,
      onChange,
      onBlur,
      className,
    },
    ref
  ) => {
    const id = useId()

    const selectedOption = useMemo(
      () => options.find((option) => option.value === value),
      [options, value]
    )

    return (
      <div className={cx('grid', className)}>
        <div className={cx('flex flex-col relative text-left text-sm')}>
          {label && (
            <label htmlFor={id} className="font-medium mb-2">
              {label}
              {!!isRequired && <span className="ml-0.5 text-red">*</span>}
            </label>
          )}

          <div
            className={cx(
              'relative appearance-none w-full h-full py-3',
              'leading-none',
              'bg-input-bg text-input-text',
              {
                'border-error': errorMessage,
                'border-input-border': !errorMessage,
                'border-b': borderBottom,
                'border px-4 rounded': !borderBottom,
              }
            )}
          >
            <select
              id={id}
              ref={ref}
              name={name}
              className="absolute block top-0 left-0 w-full h-full opacity-0 z-10 cursor-pointer focus:outline-none"
              value={value}
              onBlur={(event) => {
                if (value !== event.target.value) {
                  setValue(event.target.value)
                }

                if (onBlur) {
                  onBlur(event)
                }
              }}
              onChange={(event) => {
                if (value !== event.target.value) {
                  setValue(event.target.value)
                }

                if (onChange) {
                  onChange(event)
                }
              }}
              aria-label={label}
            >
              <option value="" disabled>
                {placeholder}
              </option>
              {options.map(({ value, title }) => (
                <option key={value} value={value}>
                  {title}
                </option>
              ))}
            </select>

            <span className="flex justify-between items-center pointer-events-none relative pr-6">
              {!!selectedOption && <>{selectedOption.title}</>}
              {!selectedOption && !!placeholder && (
                <span className="text-current opacity-[.3]">{placeholder}</span>
              )}
              {!selectedOption && !placeholder && <span className="h-[1rem]" />}
              <span className="inline-flex items-center text-2xl ml-2 absolute inset-y-0 right-0">
                <Icon
                  id={`${id}-icon`}
                  name="ChevronDown"
                  className="block"
                  color="text-current"
                />
              </span>
            </span>
          </div>

          {!!errorMessage && (
            <span role="alert" className="mt-2 font-medium text-xs text-error">
              {errorMessage}
            </span>
          )}
        </div>
      </div>
    )
  }
)

InputDropdownWithManagedState.displayName = 'InputDropdownWithManagedState'

export default InputDropdownWithManagedState
