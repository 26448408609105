import { type CSSProperties, type ReactNode } from 'react'

import {
  type SanityMuxVideo,
  type SanityMuxVideoAsset,
  type SanityVideoAspectRatioValue,
} from '@data/sanity/queries/types/video'

export type MuxVideoFormat = 'jpg' | 'png'

export type MuxVideoFitMode =
  | 'preserve'
  | 'stretch'
  | 'crop'
  | 'smartcrop'
  | 'pad'

interface MuxVideoOptions {
  format?: MuxVideoFormat
  fitMode?: MuxVideoFitMode
  width?: number
  height?: number
  time?: number
}

export interface MuxVideoError {
  type: string
}

export interface MuxPlayerProps {
  assetDocument: SanityMuxVideoAsset
  autoload?: boolean
  autoplay?: boolean
  loop?: boolean
  muted?: boolean
  showControls?: boolean
  playsInline?: boolean
  poster?: boolean | string
  width?: number | string
  height?: number | string
  className?: string
  style?: CSSProperties
  videoStyle?: CSSProperties
  children?: ReactNode
}

export const aspectRatioPadding: Record<SanityVideoAspectRatioValue, string> = {
  '1:1': 'pt-[100%]',
  '4:3': 'pt-[calc((3/4)*100%)]',
  '5:7': 'pt-[calc((7/5)*100%)]',
  '4:6': 'pt-[calc((6/4)*100%)]',
  '16:9': 'pt-[calc((9/16)*100%)]',
  '16:7': 'pt-[calc((7/16)*100%)]',
}

/**
 * Gets YouTube video ID from video URL.
 * Source: https://github.com/jmorrell/get-youtube-id
 */
export const getYouTubeVideoId = (url: string, options = { fuzzy: true }) => {
  if (/youtu\.?be/.test(url)) {
    const patterns = [
      // youtu.be/<id>
      /youtu\.be\/([^#&?]{11})/,
      // ?v=<id>
      /\?v=([^#&?]{11})/,
      // &v=<id>
      /&v=([^#&?]{11})/,
      // embed/<id>
      /embed\/([^#&?]{11})/,
      // /v/<id>
      /\/v\/([^#&?]{11})/,
    ]

    // If any pattern matches, return the ID
    for (let index = 0; index < patterns.length; ++index) {
      if (patterns[index].test(url)) {
        return patterns[index].exec(url)?.[1] ?? null
      }
    }

    if (options.fuzzy) {
      // Break it apart by certain characters and look for the 11 character key
      const tokens = url.split(/[/&?=#.\s]/g)

      for (let index = 0; index < tokens.length; ++index) {
        if (/^[^#&?]{11}$/.test(tokens[index])) {
          return tokens[index]
        }
      }
    }
  }

  return null
}

/**
 * Gets the thumbnail URL for a Mux video.
 */
export const getMuxVideoThumbnailUrl = (
  playbackId: string,
  options: MuxVideoOptions = {}
) => {
  const format: MuxVideoFormat = options.format ?? 'png'
  const width = options.width ?? 640
  const time = options.time ?? 0
  const fitMode: MuxVideoFitMode = options.fitMode ?? 'smartcrop'

  // Generate a thumbnail URL (see https://docs.mux.com/guides/video/get-images-from-a-video)
  const url = new URL(`https://image.mux.com/${playbackId}/thumbnail.${format}`)
  url.searchParams.set('width', `${width}`)
  url.searchParams.set('fit_mode', fitMode)
  url.searchParams.set('time', `${time}`)

  if (options.height) {
    url.searchParams.set('height', `${options.height}`)
  }

  return url.toString()
}

/**
 * Gets Mux video aspect ratio.
 */
export const getMuxVideoAspectRatio = (
  video: SanityMuxVideo,
  defaultRatio = 1
) => {
  try {
    const videoAspectRatio = (
      video.asset.data.aspect_ratio ?? `${defaultRatio}:1`
    )
      .split(':')
      .map((value) => Number(value))

    return videoAspectRatio[0] / videoAspectRatio[1]
  } catch {
    return defaultRatio
  }
}
