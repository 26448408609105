import { type ReactNode, Fragment } from 'react'

import { stringToHash } from '@lib/helpers'

interface NewLineToBrProps {
  children: ReactNode
}

const NewLineToBr = ({ children }: NewLineToBrProps) => {
  if (!children) {
    return null
  }

  if (typeof children === 'string') {
    return (
      <>
        {children.split('\n').map((line, index, collection) => (
          <Fragment key={stringToHash(line)}>
            {line}
            {index < collection.length - 1 && <br />}
          </Fragment>
        ))}
      </>
    )
  }

  return <>{children}</>
}

export default NewLineToBr
