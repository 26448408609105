import cx from 'classnames'
import { useCallback, useContext } from 'react'

import { SearchContext } from '@lib/search-context'
import { SiteContext } from '@lib/site-context'
import { StringsContext } from '@lib/strings-context'

import Hamburger from './hamburger'

interface MobileMenuToggleProps {
  className?: string
}

const MobileMenuToggle = ({ className }: MobileMenuToggleProps) => {
  const { toggleSearchDrawer } = useContext(SearchContext)
  const { mobileMenu, toggleMobileMenu, toggleMegaNavigation } =
    useContext(SiteContext)
  const strings = useContext(StringsContext)

  const handleClick = useCallback(() => {
    toggleMegaNavigation(false)
    toggleSearchDrawer(false)
    toggleMobileMenu(!mobileMenu.isOpen)
  }, [mobileMenu, toggleMegaNavigation, toggleMobileMenu, toggleSearchDrawer])

  return (
    <button
      onClick={handleClick}
      aria-expanded={mobileMenu.isOpen ? 'true' : 'false'}
      aria-controls="mobile-nav"
      aria-label={strings.mobileMenuLabel}
      className={cx(
        'appearance-none no-underline cursor-pointer font-inherit rounded-full',
        {
          'text-pageText': mobileMenu.isOpen,
        },
        className
      )}
    >
      <Hamburger isOpened={mobileMenu.isOpen} className="color-pageText" />
    </button>
  )
}

export default MobileMenuToggle
