import axios, { type AxiosResponse } from 'axios'

import { type Locale } from '@lib/language'
import {
  type SanityCombinedListingProductResponse,
  type ShopifyCreateCombinedListingProductResponse,
  type ShopifyGetOrCreateCombinedListingProductPayload,
} from './types'

/**
 * Fetches combined listing product by its ID.
 */
export const getCombinedListingProduct = async (
  locale: Locale,
  productId: number
) => {
  try {
    const response = await axios.get<SanityCombinedListingProductResponse>(
      '/api/sanity/combined-listing-product',
      {
        params: {
          product_id: productId,
        },
        headers: {
          'Content-Type': 'application/json',
          'X-Locale': locale,
        },
      }
    )

    if (response.status !== 200) {
      throw new Error(response.data.error)
    }

    if (!response.data.product) {
      throw new Error('Product not found')
    }

    return response.data.product
  } catch (error) {
    console.log(error)
  }
}

/**
 * Sends a request to create a Shopify combined listing product.
 */
export const getOrCreateCombinedListingProduct = async (
  locale: string,
  productIds: number[],
  size: string,
  passepartoutHoleSize?: string
) => {
  try {
    const payload: ShopifyGetOrCreateCombinedListingProductPayload = {
      productIds,
      size,
      passepartoutHoleSize,
    }

    const response = await axios.post<
      ShopifyCreateCombinedListingProductResponse,
      AxiosResponse<ShopifyCreateCombinedListingProductResponse>,
      string
    >(
      '/api/shopify/get-or-create-combined-listing-product',
      JSON.stringify(payload),
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Locale': locale,
        },
      }
    )

    if (response.status !== 200) {
      throw new Error(JSON.stringify(response.data))
    }

    return response.data
  } catch (error) {
    console.log(error)
  }
}
