import Head from 'next/head'
import { useContext } from 'react'

import { type SanityPage } from '@data/sanity/queries/types/page'
import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import { getSanityImageUrl } from '@lib/image'
import { MetadataContext } from '@lib/metadata-context'
import { ShopContext } from '@lib/shop-context'

export type SchemaString = string | undefined

interface HeadSeoProps {
  site: SanitySiteFragment
  page: SanityPage
  canonicalUrl?: string
  schemas?: SchemaString[]
}

const HeadSeo = ({ site, page, canonicalUrl, schemas }: HeadSeoProps) => {
  const { metadataOverride } = useContext(MetadataContext)
  const { shopifyStoreDomain } = useContext(ShopContext)

  const metaTitle =
    metadataOverride?.metaTitle ??
    page.seo?.metaTitle ??
    site.seoSettings?.metaTitle
  const metaDescription =
    metadataOverride?.metaDesc ??
    page.seo?.metaDesc ??
    site.seoSettings?.metaDesc
  const shareTitle =
    metadataOverride?.shareTitle ??
    page.seo?.shareTitle ??
    site.seoSettings?.shareTitle
  const shareDescription =
    metadataOverride?.shareDesc ??
    page.seo?.shareDesc ??
    site.seoSettings?.shareDesc
  const shareGraphicUrl = getSanityImageUrl(
    metadataOverride?.shareGraphic ??
      page.seo?.shareGraphic ??
      site.seoSettings?.shareGraphic,
    {
      width: 1200,
      height: 630,
    }
  )

  const siteTitle = site.seoSettings?.siteTitle

  // Disable indexing for non-production deployments
  const noIndex = true
  // TODO: Uncomment after launch.
  // const noIndex = !!page.noIndex || process.env.NEXT_PUBLIC_APP_ENV !== 'production'

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="format-detection" content="telephone=no" />
      {noIndex && <meta name="robots" content="noindex" />}

      {/* Deprecated. Encourage clients to use GTM instead. */}
      {/* {site.generalSettings?.facebookDomainVerification && (
        <meta
          name="facebook-domain-verification"
          content={site.generalSettings.facebookDomainVerification}
        />
      )} */}

      <link
        rel="icon"
        type="image/png"
        href="/favicon-96x96.png"
        sizes="96x96"
      />
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      <link rel="shortcut icon" href="/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <meta name="apple-mobile-web-app-title" content="by ADJ Denmark" />
      <link rel="manifest" href="/site.webmanifest" />

      <link rel="preconnect" href={`https://${shopifyStoreDomain}`} />
      <link
        rel="preconnect"
        href="https://cdn.sanity.io"
        crossOrigin="anonymous"
      />

      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="title" property="og:title" content={shareTitle} />
      <meta name="twitter:title" content={shareTitle} />
      <meta
        name="description"
        property="og:description"
        content={shareDescription}
      />
      <meta name="twitter:description" content={shareDescription} />

      {shareGraphicUrl && (
        <>
          <meta name="image" property="og:image" content={shareGraphicUrl} />
          <meta name="twitter:image" content={shareGraphicUrl} />
        </>
      )}

      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="og:site_name" content={siteTitle} />

      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

      {schemas && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{"@context":"https://schema.org","@graph":[${schemas
              .filter(Boolean)
              .join(',')}]}`,
          }}
        />
      )}
    </Head>
  )
}

export default HeadSeo
